import {Component, Input} from '@angular/core';
import {UserLeanModel} from '@core/models/user/user.model';
import {coerceBoolean} from '@core/decorators/coerce-boolean';
import {Router} from '@angular/router';

@Component({
    selector: 'omt-user-link',
    templateUrl: './user-link.component.html',
    styleUrls: ['./user-link.component.scss']
})
export class UserLinkComponent {
    @Input() user: UserLeanModel;

    @coerceBoolean()
    @Input() showAvatar = true;

    constructor(private readonly router: Router) {
    }

    navigate(): void {
        this.router.navigate(['/profile/general', this.user.id]).then();
    }
}
