import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {UserLeanModel} from '@core/models/user/user.model';
import {UserService} from '@core/services/user.service';
import {coerceBoolean} from '@core/decorators/coerce-boolean';

@Component({
    selector: 'omt-user-link-container',
    templateUrl: './user-link-container.component.html',
    styleUrls: ['./user-link-container.component.scss']
})
export class UserLinkContainerComponent {
    @Input() set userId(userId: number) {
        if (!userId) {
            return;
        }

        this.user$ = this.userService.getUserById(userId, ['id', 'avatarurl', 'displayname', 'department', 'jobrole', 'location', 'businessemail', 'businessphone']);
    }

    @coerceBoolean()
    @Input() showAvatar = true;

    user$: Observable<UserLeanModel>;

    constructor(private readonly userService: UserService) {
    }
}
